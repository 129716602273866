/* eslint-disable import/prefer-default-export */
export const Notification = {
  TYPE: {
    SUCCESS: 'success',
    WARNING: 'warning',
    INFO: 'info',
    ERROR: 'error'
  },

  queue: [],

  clear: () => {
    sessionStorage.setItem('queue', []);
  },

  add: (message, type, milliseconds) => {
    // milliseconds = (milliseconds === undefined || milliseconds == null) ? 2000 : milliseconds;

    const item = { message, type, time: milliseconds };

    Notification.queue.push(item);

    if (milliseconds) {
      setTimeout(() => {
        Notification.remove(Notification.queue.length - 1);
      }, milliseconds);
    }
  },

  getQueue: () => Notification.queue,

  remove: (index = 0) => {
    if (index === 0) {
      Notification.queue.shift();
    } else {
      Notification.queue.splice(index, 1);
    }
  }
};

export default Notification;
