export default {
  name: 'oCheckbox',
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    value: Boolean
  },
  methods: {
    onChange(event) {
      this.$emit('input', event.target.checked);
    }
  }
};
