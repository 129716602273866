import oBlock from '@/objects/o-block/o-block.vue';
import oLogo from '@/objects/o-logo/o-logo.vue';
import oForm from '@/objects/o-form/o-form.vue';
import oButton from '@/objects/o-button/o-button.vue';
import oCheckbox from '@/objects/o-checkbox/o-checkbox.vue';
import oLabel from '@/objects/o-label/o-label.vue';
import oInput from '@/objects/o-input/o-input.vue';
import mSession from '@/modules/m-session/m-session.vue';
import CONST from "@/utils/Constants";
import ObjectHelper from "@/helpers/ObjectHelper";
import Notification from "@/utils/Notification";
import ErrorHelper from "../../../helpers/ErrorHelper";
import Api from "@/helpers/ApiHelper";

export default {
  name: 'Login',
  components: {
    'o-block': oBlock,
    'o-logo': oLogo,
    'o-form': oForm,
    'o-button': oButton,
    'o-checkbox': oCheckbox,
    'o-label': oLabel,
    'o-input': oInput,
    'm-session': mSession
  },
  data() {
    return {
      loading: false,
      password: null,
      email: null,
      rememberMe: false,
      formErrors: {
        email: false,
        password: false
      }
    };
  },
  methods: {
    login() {
      let data = {};
      data[CONST.KEY.AUTH.EMAIL] = this.email;
      data[CONST.KEY.AUTH.PASSWORD] = this.password;

      this.formErrors.email = !this.email;
      this.formErrors.password = !this.password;

      if (this.email && this.password) {
          this.loading = true;

          // eslint-disable-next-line
          Api.post(CONST.API.ROUTE.SESSION.LOGIN, data, 'basic')
              .then(response => {

                  if (response !== undefined) {

                      if (ObjectHelper.exists(response.data.token) && ObjectHelper.exists(response.data.roles)) {
                          localStorage.setItem(CONST.KEY.AUTH.ACCESS_TOKEN, response.data.token);
                          localStorage.setItem(CONST.KEY.USER.ROLE, response.data.roles[0]);
                          localStorage.setItem(CONST.KEY.USER.ID, response.data.id);

                          this.$router.push(CONST.ROUTE.CHATS.OVERVIEW);

                          Notification.add(
                              this.$t("notifications.success.login"),
                              Notification.TYPE.SUCCESS,
                              5000
                          );
                      } else {
                          localStorage.clear();
                          this.$router.push(CONST.ROUTE.SESSION.LOGIN);
                      }
                  }
              })
              .catch(error => {
                  ErrorHelper.catch(error, this);
                  localStorage.clear();
              })
              .finally(() => {
                  this.loading = false;
              });
      }
    }
  }
};
